import React from "react";
import useWindowDimensions from "../hooks/UseWindowDimensions";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import styles from "../styles/NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faFacebook,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const NavBar = () => {
  const { height, width } = useWindowDimensions();

  // To set either flex column or row on the central content elements
  // based on device width
  let flexClass;
  let reverseFlexClass;
  if (width <= 648) {
    flexClass = "flex-column"
    reverseFlexClass ="flex-row"
  }
  else {
    flexClass = "flex-row"
    reverseFlexClass ="flex-column"
  }

  return (
    <Navbar className={styles.NavBar} bg="transparent" variant="dark">
      <Container>
        <Row>
          <Col xs={12} className={`d-flex align-items-center justify-content-center ${reverseFlexClass}`}>
          <iframe
                className={`${styles.Video} mb-5`}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/OEMh2b8wu6s"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            <Nav className={`me-auto d-flex justify-content-center ${flexClass}`}>
              <Nav.Link
                className="d-flex flex-row align-items-center"
                href="https://youtu.be/OEMh2b8wu6s"
                target="_blank"
              >
                <span className={styles.Youtube}>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="mr-2 p-2"
                    width="30"
                  />
                </span>{" "}
                <span className={`${styles.LinkText} ${styles.HideLarge}`}>Watch on Youtube</span>
              </Nav.Link>
              <Nav.Link
                className="d-flex flex-row align-items-center"
                href="https://open.spotify.com/track/4f6BF5p56jCgEoNUL27Ej0?si=6a017dd422684d95"
                target="_blank"
              >
                <span className={styles.Spotify}>
                  <FontAwesomeIcon
                    icon={faSpotify}
                    className="mr-2 p-2 spotify"
                    width="30"
                  />
                </span>{" "}
                <span className={styles.LinkText}>Listen on Spotify</span>
              </Nav.Link>
              <Nav.Link
                className="d-flex flex-row align-items-center"
                href="https://music.apple.com/se/album/follow-the-herd-single/1612955204"
                target="_blank"
              >
                <span className={styles.Apple}>
                  <FontAwesomeIcon
                    icon={faApple}
                    className="mr-2 p-2"
                    width="30"
                  />
                </span>{" "}
                <span className={styles.LinkText}>Buy on Apple Music</span>
              </Nav.Link>
              <Nav.Link
                className="d-flex flex-row align-items-center"
                href="https://facebook.com/DarwicheRock"
              >
                <span className={styles.Facebook}>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="mr-2 p-2"
                    width="30"
                    target="_blank"
                  />
                </span>{" "}
                <span className={styles.LinkText}>Find us on Facebook</span>
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default NavBar;
