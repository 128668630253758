import React from "react";
import styles from "../styles/Footer.module.css";

const Footer = () => {
    return (
      <footer className="d-flex flex-row">
        <span className="p-4">Powered by React</span> <a href="https://angrycat-design.com" target="_blank" className={`p-4 ${styles.Footer}`}>© Angry Cat Design</a>
      </footer>
    );
  };
  
  export default Footer;